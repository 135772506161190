/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap');

* {
  font-family: "Open Sans", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 300;
  font-style: normal !important;
  font-variation-settings: "wdth" 100 !important;
} */

.css-blurry-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background-blend-mode: overlay;
  background: linear-gradient(rgba(255, 255, 255, 0), rgb(243 244 246) 40%);
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.PhoneInputCountrySelectArrow,
.PhoneInputCountryIcon,
.PhoneInputCountrySelect {
  display: none;
}

.phone-input-ecoints > input::placeholder,
.phone-input-ecoints > input {
  font-size: 13px !important;
}

.phone-input-ecoints > input:hover,
.phone-input-ecoints > input:focus,
.phone-input-ecoints > input:focus-visible,
.phone-input-ecoints > input:focus-within,
.phone-input-ecoints > input {
  height: 44px !important;
  width: 100%;
  outline: none !important;
  width: 100%;
  height: 44px;
  font-size: 16px !important;
  line-height: 20px;
  letter-spacing: 0.32px;
  font-family: inherit;
  color: #212121 !important;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  caret-color: #4877ce;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 16px;
  padding-right: 34px;
  outline: none;
}
